import React, { createContext, useContext } from 'react';
import toast from 'react-hot-toast/headless';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAPI } from '../libs/libAPI';
import Loading from '../pages/Loading';
import { FileBrowserViewMode } from '../types/FileBrowserViewMode';
import { GenericDataElement, GenericDataElementSchema } from '../types/GenericDataList';
import ThemeType from '../types/ThemeType';
import { useUserContext } from './UserContext';

export interface IUserPreferenceContext {
  readonly preferences: GenericDataElement;
  readonly patchPreferences: (patch: GenericDataElement) => void;
  readonly setPreference: (preferenceName: string, value: any) => void;
};

const UserPreferenceContext = createContext<IUserPreferenceContext | null>(null);
UserPreferenceContext.displayName = 'UserPreferenceContext';

interface UserPreferenceContextProviderProps {
  children: React.ReactNode;
}

export const UserPreferenceContextProvider: React.FC<UserPreferenceContextProviderProps> = (props) => {
  const api = useAPI();
  const userContext = useUserContext();
  const queryClient = useQueryClient();

  const userPreferenceQuery = useQuery(['preferences', userContext.realUser.id], async () => {
    const result = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/user/me/preferences`, {
      method: 'GET'
    });

    const preferences = await GenericDataElementSchema.nullable().parseAsync(await result.json());

    return preferences ?? {};
  }, {
    cacheTime: 60 * 60,
    staleTime: 5 * 60
  });

  const mutatePreferences = useMutation(async (preferencesPatch: GenericDataElement) => {
    const result = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/user/me/preferences`, {
      method: 'PATCH',
      body: JSON.stringify(preferencesPatch)
    });

    const preferences = await GenericDataElementSchema.parseAsync(await result.json());

    return preferences;
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['preferences', userContext.realUser.id], data);
    },
    onError: () => {
      toast.error('Failed to save preference');
    }
  });

  if (userPreferenceQuery.data == null) {
    return <Loading fullScreen={true} />;
  }

  const setPreference = (preferenceName: string, value: any): void => {
    mutatePreferences.mutate({
      [preferenceName]: value
    });
  };

  const userPreferenceContext: IUserPreferenceContext = {
    preferences: userPreferenceQuery.data,
    patchPreferences: mutatePreferences.mutate,
    setPreference
  };

  return <UserPreferenceContext.Provider value={userPreferenceContext}>
    {props.children}
  </UserPreferenceContext.Provider>;
};

export const useUserPreferences = (): IUserPreferenceContext => {
  const context = useContext(UserPreferenceContext);

  if (context == null) {
    throw Error('Must be used within UserPreferenceContextProvider');
  }

  return context;
};

interface PrefDef {
  key: string;
  defaultValue: any;
}

export type PrefName = 'ThemeMode' | 'FilesViewMode';

type Prefs = {
  [name in PrefName]: PrefDef;
};

export const PREFERENCES: Prefs = {
  ThemeMode: {
    key: 'portalThemeMode',
    defaultValue: ThemeType.Dynamic
  },
  FilesViewMode: {
    key: 'portalFilesViewMode',
    defaultValue: FileBrowserViewMode.Icon
  }
};
