import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast/headless';
import { useAuth } from 'react-oidc-context';

import { GetTokenSilentlyOptions, useAuth0, User as AuthZeroUser } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import Button from '../components/Library/Button';
import { portalFetch } from '../libs/libAPI';
import { Permission } from '../libs/libPermissions';
import { sessionObjectStorage } from '../libs/ObjectStorage';
import AuthError from '../pages/Error/Auth';
import Loading from '../pages/Loading';
import Auth0User from '../types/Auth0User';
import WDUser, { WDUserSchema } from '../types/WDUser';

const KEY_IMPERSONATE = 'impersonate';

export interface IUserContext {
  readonly authUser: any;
  readonly realUser: WDUser;
  readonly realUserEmail?: string;
  readonly apparentUser: WDUser;
  readonly apparentUserEmail?: string;
  readonly apparentUserPermissions: Permission[];
  readonly isImpersonating: boolean;
  readonly accessToken: string;
  readonly logout: () => void;
  readonly getApiToken: (options?: GetTokenSilentlyOptions) => Promise<string>;
  readonly impersonate: (email: string) => Promise<void>;
  readonly exitImpersonate: () => void;
  readonly hasPermission: (permission: Permission) => boolean;
}

const UserContext = createContext<IUserContext | null>(null);

UserContext.displayName = 'UserContext';

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = (props) => {
  // const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const auth = useAuth();

  const [impersonateEmail, setImpersonateEmail] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // This handles setting back up the impersonation if the user refreshes the browser.
  useEffect(() => {
    if (sessionObjectStorage.exists(KEY_IMPERSONATE)) {
      if (auth.isAuthenticated) {
        const email = sessionObjectStorage.get<string>(KEY_IMPERSONATE);
        setImpersonateEmail(email);
      }
    }
  }, [auth]);

  const getUserInfo = async (email: string): Promise<WDUser | Response> => {
    const token = auth.user?.access_token ?? '';
    const response = await portalFetch(`${import.meta.env.VITE_API_URI}/v1/user/me`, token, {
      method: 'GET'
    }, email);

    if (response.ok) {
      const data: WDUser = await WDUserSchema.parseAsync(await response.json());
      return data;
    }

    return response;
  };

  const getUserPermissions = (user: WDUser): Permission[] => {
    return user.permissions.map(Permission.fromString);
  };

  const realUserEmail: string | null = auth.user?.profile.email ?? null;

  const realUserQuery = useQuery(['user', realUserEmail], async () => {
    if (realUserEmail === null) return null;

    const realUserData = await getUserInfo(realUserEmail);

    // Error Path
    // TODO get better error text
    if (realUserData instanceof Response) {
      switch (realUserData.status) {
        case 404:
          setError('We could not find you in our system.');
          break;
        case 500:
          setError('We encountered a problem while looking up your information.');
          break;
      }
      setHasError(true);
      return null;
    }

    setHasError(false);
    return realUserData;
  }, {
    cacheTime: 60 * 60 * 1000
  });

  const impersonateUserQuery = useQuery(['user', impersonateEmail], async () => {
    if (realUserEmail === null || impersonateEmail === null) return null;

    const impersonatedUserData = await getUserInfo(impersonateEmail);

    // Error Path
    if (impersonatedUserData instanceof Response) {
      switch (impersonatedUserData.status) {
        case 404:
          toast.error('Could not find user to impersonate');
          break;
        case 500:
          toast.error('A problem occured when trying to get inpersonated users information');
          break;
      }
      exitImpersonate();
      return null;
    }

    return impersonatedUserData;
  }, {
    cacheTime: 60 * 60 * 1000
  });

  const isImpersonateLoading: boolean = impersonateUserQuery.isLoading;

  const userLogout = (): void => {
    exitImpersonate();
    void auth.signoutRedirect({
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`
    });
    // logout({
    //   logoutParams: {
    //     returnTo: `${window.location.protocol}//${window.location.host}`
    //   }
    // });
  };

  const impersonate = async (email: string): Promise<void> => {
    sessionObjectStorage.set(KEY_IMPERSONATE, email);
    console.log('impersonate:', email);
    setImpersonateEmail(email);
  };

  const exitImpersonate = (): void => {
    sessionObjectStorage.remove(KEY_IMPERSONATE);
    setImpersonateEmail(null);
  };

  if (hasError) {
    return <AuthError errorText={error} errorText2='Please contact your IT Admin for assistance.'>
      <Button variant='primary' className='px-3 py-2 text-lg' onClick={userLogout}>Logout</Button>
    </AuthError>;
  }

  if (auth.isLoading || isImpersonateLoading || !auth.isAuthenticated || auth.user == null || realUserQuery.data == null) return <Loading fullScreen={true} />;

  const userPermissions: Permission[] = getUserPermissions(impersonateUserQuery.data ?? realUserQuery.data);

  const hasPermission = (permission: Permission): boolean => userPermissions.find(p => p.equals(permission)) != null;

  const userContext: IUserContext = {
    authUser: auth.user.profile,
    realUser: realUserQuery.data,
    realUserEmail: realUserEmail ?? undefined,
    apparentUser: impersonateUserQuery.data ?? realUserQuery.data,
    apparentUserEmail: impersonateEmail ?? realUserEmail ?? undefined,
    apparentUserPermissions: userPermissions,
    isImpersonating: impersonateUserQuery.data != null,
    accessToken: auth.user.access_token,
    logout: userLogout,
    getApiToken: async (): Promise<string> => auth.user?.access_token ?? '',
    impersonate,
    exitImpersonate,
    hasPermission
  };

  Sentry.setUser({
    email: userContext.apparentUserEmail
  });

  return <UserContext.Provider value={userContext}>
    {props.children}
  </UserContext.Provider>;
};

export const useUserContext = (): IUserContext => {
  const context = useContext(UserContext);

  if (context === null) {
    throw Error('Must be used within UserContextProvider');
  }

  return context;
};
